<template>
    <OModal ref="checkItemsUrlModalRef" >    
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="hstack gap-2">
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Modify URLs") }}
                        </h4>                        
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body row">
                    <ODataTable :data-object="dsCheckItems_ItemsUrls" newRecordsPosition="bottom" disableFilterRow hideMultiselectColumn :hideGridMenu="true" filter-row ref="grid" style="max-height:500px;min-height:200px">
                        <OColumn field="URL" width="200" flexWidth="40" sortable editable></OColumn> 
                        <OColumn field="Name" width="300" flexWidth="60" sortable editable></OColumn> 
                    </ODataTable>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-outline-primary" data-bs-dismiss="modal" @click="checkItemsDataObject.refreshRow();" aria-label="Close">{{ $t("Close") }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
import { OColumn, ODataTable } from 'o365-datagrid';
import { OModal } from 'o365-ui-components';
import { useDataObjectEventListener } from 'o365-vue-utils';
import { ref, defineExpose } from 'vue';

const checkItemsUrlModalRef = ref();
const checkItemId = ref(null);

const dsCheckItems_ItemsUrls = $getDataObjectById("dsCheckItems_ItemsUrls"); 

useDataObjectEventListener(dsCheckItems_ItemsUrls, "BeforeSave", e => {
    e.values["CheckItem_ID"] = checkItemId.value;
});

const props = defineProps({
    checkItemsDataObject: {
        type: Object,
        required: true
    }
});

defineExpose({ show });

async function show(pCheckItemId) { 
    checkItemId.value = pCheckItemId;
    loadUrls(pCheckItemId);
    checkItemsUrlModalRef.value?.show();
}

function loadUrls(checkItemId) {
    dsCheckItems_ItemsUrls.recordSource.whereClause = `CheckItem_ID = ${checkItemId}`;
    dsCheckItems_ItemsUrls.load();
}

</script>